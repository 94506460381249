<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t('MENU.commission_management') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
      </div>
      <div class="card-body">
        <div class="card-body p-0">
          <div class="form-group row">
            <div class="col-lg-6 mb-5">
              <label>{{$t('commission.agent_name')}}</label>
              <div class="input-group">
                <multiselect
                    :class="validation && validation.agent_id ? 'is-invalid' : ''"
                    v-model="agent"
                    :placeholder="$t('commission.agent_name')"
                    label="name"
                    track-by="id"
                    :options="agents"
                    :multiple="false"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false"
                >
                </multiselect>
                <span v-if="validation && validation.agent_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.agent_id[0] }}
                </span>
              </div>
            </div>

            <!-- <div class="col-lg-6 mb-5">
              <label>{{ $t('commission.discount_rate') }}<span class="text-danger">*</span></label>
              <input type="number" v-model="data.discount_rate" class="form-control"
                     :class="validation && validation.discount_rate ? 'is-invalid' : ''"
                     :placeholder="$t('commission.discount_rate')"
              />
              <span v-if="validation && validation.discount_rate" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.discount_rate[0] }}
                </span>
            </div> -->
            <div class="col-lg-6 mb-5">
              <label>{{ $t('commission.commission_rate') }}<span class="text-danger">*</span></label>
              <input type="number" v-model="data.commission_rate" class="form-control"
                     :class="validation && validation.commission_rate ? 'is-invalid' : ''"
                     :placeholder="$t('commission.commission_rate')"
              />
              <span v-if="validation && validation.commission_rate" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.commission_rate[0] }}
                </span>
            </div>


            <div class="col-lg-6 mb-5">
              <label>{{ $t('commission.general_interval') }}</label>
              <div class="input-group">
                <input type="number" v-model="data.interval_no" class="form-control"
                       :class="validation && validation.interval_no ? 'is-invalid' : ''"/>

                <select name="" id="currency_id" v-model="data.interval_type" class="custom-select"
                        :class="validation && validation.interval_type ? 'is-invalid' : ''">
                  <option v-for="row in interval_type_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>

              </div>
              <span v-if="validation && validation.interval_type" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.interval_type[0] }}
                </span>
              <span v-if="validation && validation.interval_no" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.interval_no[0] }}
                </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('commission.applied_date') }}<span class="text-danger">*</span></label>
              <input type="date" v-model="data.applied_date" class="form-control"
                     :class="validation && validation.commission_rate ? 'is-invalid' : ''"
                     :placeholder="$t('commission.applied_date')"
              />
              <span v-if="validation && validation.applied_date" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.applied_date[0] }}
                </span>
            </div>
          </div>

        </div>
        <div class="card-footer pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6">
              <button
                  type="reset"
                  class="btn btn-primary mr-2"
                  @click="save()"
              >
                {{ $t('save') }}
              </button>
              <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-commissions",
  data() {
    return {
      mainRouteDependency: 'base/dependency',
      mainRoute: 'agents/commissions',
      data: {
        agent_id: this.$route.params.agent_id ? this.$route.params.agent_id : null,
        discount_rate: null,
        commission_rate: null,
        interval_no: null,
        interval_type: 1,
        applied_date: null,
      },

      isEditing: false,
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      validation: null,
      agents: [],
      agent: null,

      interval_type_list: [
        {id: 1, title: this.$t('commission.year')},
        {id: 2, title: this.$t('commission.month')},
      ],

    };
  },
   watch: {
    "agent": function (val) {
        if (val) {
            this.data.agent_id = val.id;
        }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'commission.index'});
          })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    update() {
      ApiService.put(this.mainRoute + `/${this.$route.params.id}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'commission.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      ApiService.get(this.mainRoute + `/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.agent_id = response.data.data.agent_id;
        this.data.discount_rate = response.data.data.discount_rate;
        this.data.commission_rate = response.data.data.commission_rate;
        this.data.interval_no = response.data.data.interval_no;
        this.data.interval_type = response.data.data.interval_type;
        this.data.applied_date = response.data.data.applied_date;

        this.agent = response.data.data.agent;
      });
    },


    getAgent() {
      // if (filter && filter.length >= 3) {,{params: {filter: filter}}
        ApiService.get(`${this.mainRouteDependency}/agents`).then((response) => {
          this.agents = response.data.data;
          if (this.data.agent_id){
            this.agent = response.data.data.find((row)=>row.id == this.data.agent_id);
          }
        });
      // }
    },
  },


  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.commission_management"),
      route: '/agents/commission'
    }, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    this.getAgent();

    if (this.idEditing) {
        this.getData();
    }
  },
};
</script>


